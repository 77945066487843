.loadingContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 19;
    // background-color: #888888;
    opacity: 0.3;
}

.loadingSpinner {
    position: absolute;
    top: calc(50% - 40px);
    left: calc(50% - 30px);
    z-index: 9999;
}