.container {
  padding-bottom: 20px;

  .vinEditMessage {
    color: #FF9000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-left: 30px;
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-left: 30px;
    margin-right: 30px;

    > div {
      width: 48%;

      > div {
        border-radius: 0;
      }

      label {
        font-size: 16px;
        top: 0;
        color: #8F8F8F;
      }

      > div > input {
        font-size: 16px;
        padding-top: 23px;
        padding-bottom: 6px;
        color: #666;
        -webkit-text-fill-color: #666;
      }
    }

    .radioGroup {
      width: 100%;
      margin-bottom: 10px;

      .radioLabel {
        max-height: 32px;
        color: #333;

        >span {
          font-size: 14px;
        }
      }
    }

    .select {
      margin-top: 6px;
      margin-bottom: 1px;
      height: 52px;

      label {
        margin-left: 10px;
        top: 2px !important;
      }

      >div {
        border-radius: 0 !important;
        height: 52px;

        >div:first-of-type {
          border-radius: 0 !important;
          padding: 14px 10px 10px 10px;
          font-size: 16px;
          color: #666;
        }
      }
    }
  }
}

.option {
  li {
    font-weight: 400;
    font-size: 16px;
    font-style: normal;
    color: #666;
  }
}

.suggestionItem {
  padding: 10;
  font-size: 20px;
}

.autocomplete {

  >div>div {

    >div {
      border-radius: 0;
    }

    >label {
      font-size: 16px !important;
    }
  }
}
