.container {
  padding-bottom: 20px;

  .image {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    cursor: pointer;
    width: 158px;
    height: 105px;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    >div {
      width: 158px;
      height: 105px;
      border-radius: 10px;
    }

    >.addPhotoLabel {
      background-color: black;
      opacity: 0.5;
      height: 30px !important;
      position: absolute;
      margin-top: 75px;
      color: white;
      text-align: center;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      font-size: small;

      >label {
        cursor: pointer;
      }
    }
  }

  .vinEditMessage {
    color: #FF9000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-left: 30px;
  }

  .imageOnly {
    cursor: auto;
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-left: 30px;
    margin-right: 30px;

    >div {
      width: 48%;

      >p {
        position: absolute;
        top: 50px;
      }

      >div {
        border-radius: 0;
      }

      label {
        font-size: 16px;
        top: 0;
      }

      >div>input {
        font-size: 16px;
        padding-top: 23px;
        padding-bottom: 6px;
      }
    }

    .disabled {
      background-color: #ccc !important;

      >div {
        background-color: #ccc !important;
      }

      label {
        color: #fff;
      }
    }

    .controlLabel {
      margin-left: 15px;
      color: #666;
      font-size: 16px;
    }

    .controlRoot {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      background-color: #e8e8e8;
      width: 48%;
      margin-left: 0;
      border-bottom: 1px solid #2a2a2a;
      height: 52px;
      margin-top: 8px;
      margin-bottom: 4px;
    }

    .cpSwitch {
      >span>span {
        color: #ccc !important;

        >span {
          width: 20px;
          height: 20px;
        }
      }

      >span:nth-of-type(2) {
        height: 14px;
        margin-left: -3px;
        margin-top: 0;
        margin-right: 5px;
      }
    }

    .cpSwitchActive {
      >span>span {
        color: #0091ea !important;

        >span {
          width: 20px;
          height: 20px;
        }
      }

      >span:nth-of-type(2) {
        height: 14px;
        margin-left: -3px;
        margin-top: 0;
        margin-right: 5px;
        opacity: 0.5 !important;
      }
    }

    .states {
      background-color: #e8e8e8;
      height: 52px;
      margin-top: 4px;

      label {
        top: -8px;
        padding-left: 15px;
        font-size: 16px;
      }

      input {
        font-size: 16px;
        padding-left: 15px;
        padding-bottom: 13px;
      }

      >div>div {
        margin-top: 4px !important;

        >p {
          padding-left: 15px;
        }

        >div {
          height: 32px;
        }
      }
    }

    .statesFocused {
      label {
        font-size: 14px;
        top: -21px;
      }
    }

    .disabledField {
      background-color: #ccc;
      height: fit-content;

      >label {
        color: white !important;
      }
    }

    .disabledPlateStateField {
      background-color: #b3b3b3;

      >div>div>label {
        color: white !important;
      }
    }
  }

  .selectRow {
    >div:nth-of-type(2) {
      margin-top: 5px;
      margin-bottom: 1px;
      height: 52px;

      >div {
        height: 52px;

        >div {
          color: #666;
          font-size: 16px;
        }
      }
    }
  }

  .upgradeMessage {
    font-family: 'Proxima Nova', 'Roboto', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    margin-left: 30px;

    .hyperLink {
      color: #0091EA;
    }
  }
}