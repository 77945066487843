.equipmentCard {
  width: 100%;
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.1);
  height: 316px;

  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 10px rgba(0, 0, 0, 0.05);
  }

  .header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #F0F0F0;
    padding: 8px 16.5px;

    .ownerType {
      font-size: 17px;
      line-height: 24px;
      font-weight: 600;
      border-radius: 20px;
      padding: 7px;
      color: #262626;
    }

    .active {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;
      font-size: 15px;
      color: #333;
    }
  }

  .imageWrapper {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;

    .image {
      >div {
        width: 158px;
        height: 105px;
        border-radius: 10px;
        overflow-wrap: anywhere;
      }

      .addPhotoLabel {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: black;
        opacity: 0.5;
        height: 30px !important;
        position: absolute;
        margin-top: 75px;
        color: white;
        text-align: center;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        font-size: small;
        bottom: 0;

        >label {
          cursor: pointer;
        }
      }
    }
  }

  .textWrapper {
    padding: 10px 10px 30px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .textContainer {
      display: flex;
      flex-direction: column;
    }

    .textColumn {
      max-width: calc(50% - 10px);
    }
  }

  .label {
    font-size: 12px;
    line-height: 24px;
    color: #666;
  }

  .value {
    font-size: 14px;
    line-height: 24px;
    font-weight: bold;
    color: #666;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.truckCard>div {
  display: flex;
  flex-direction: column;
}

.imageDialog {
  >div>div>div {
    border-radius: 10px;
    width: 830px;
    height: 762px;
    padding: 20px 20px 0 20px;
    box-sizing: content-box;

    >div {
      right: 4px !important;
    }

    >div:nth-of-type(2) {
      margin-bottom: 27px;
    }
  }
}

@keyframes slidein {
  from {
    margin-left: 100%;
  }

  to {
    margin-left: -100%;
  }
}