.container {
  height: calc(100% - 60px);
  max-height: calc(100% - 60px);
  position: relative;
  margin: 30px;

  .button {
    display: flex;
    align-items: center;
    justify-content: space-between;

    #createButton {
      font-size: 16px;
      letter-spacing: -0.1px;
      line-height: 40px;
      height: 40px;
      width: 150px;
      border-radius: 0;
      box-shadow: none;
      text-transform: none;
      color: white;
      background-color: #0091ea;
      padding: 0;
      flex: 0 0 auto;
      font-weight: 700;

      .icon {
        position: absolute;
        left: 3px;
        top: 4px;
        font-size: 27px !important;
      }
    }
  }

  .tabBarContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    padding-top: 0.5em;
    height: 38px;
    z-index: 1;
    margin-top: 30px;
  }

  .filterBarContainer {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .gridWrapper {
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 236px);
    width: 100%;
  }

  .summaryDialog {
    padding: 20px 5px;
    height: 785px;
  }

  .equipmentGrid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin: auto;
    overflow-y: overlay;
    padding-bottom: 30px;
    align-self: center;
    width: 100%;

    >div {
      width: calc(20% - 20px);
    }
  }

  .truckEdit {
    >div:first-of-type>div {
      padding: 60px 20px 10px 20px;
      border-radius: 10px;
      width: 816px;
      // height: 558px;
      box-sizing: content-box;

      >div>div{
        height: 100%;
      }

      >div:first-of-type {
        top: -36px !important;
        right: 5px !important;
      }
    }
    >div{
      z-index: 1300;
    }
  }
}

@media only screen and (max-width: 1500px) {
  .container {
    .equipmentGrid>div {
      width: calc(25% - 50px);
    }
  }
}

@media only screen and (max-width: 1250px) {
  .container {
    .equipmentGrid>div {
      width: calc(33% - 20px);
    }
  }
}

@media only screen and (max-width: 980px) {
  .container {
    .equipmentGrid {
      justify-content: center;

      >div {
        width: calc(50% - 20px);
      }
    }
  }
}

@media only screen and (max-width: 780px) {
  .container {
    .equipmentGrid {
      justify-content: center;

      >div {
        width: calc(100%);
      }
    }
  }
}
