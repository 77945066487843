.vinModal {
    padding: 35px 45px;
    width: 588px;
    height: 287px;
    top: 386px;
    left: 432px;
    border-radius: 5px;

  }

  .modalHeader {
    display: flex;
    margin-left: -16px;
    margin-right: -16px;

    > .headerText {
      > h2 {
        font-family: 'Proxima Nova', 'Roboto', Sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
      }

      display: flex;
      justify-content: flex-start;
      width: 90%;
      align-items: center;
      margin-left: 16px;
    }

    > .closeIcon {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 10%;
      color: #8c8c8c;
      margin-right: 16px;
      svg {
        cursor: pointer;
        }
    }

  }

  .modalBody {
    > p {
      font-family: 'Proxima Nova', 'Roboto', Sans-serif;
      font-size: 15px;
      font-weight: 300;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      display: flex;
      width: auto;
    }

  }

  .modalFooter {
    margin-top: 56px;
    display: flex;
    height: 42px;

    > .inputNewButton {
      display: flex;
      justify-content: flex-start;
      width: 50%;
      > button {
        font-family: 'Proxima Nova', 'Roboto', Sans-serif;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        border: 2px solid rgba(0, 145, 234, 1);
      }
    }

    > .editButton {
      display: flex;
      justify-content: flex-end;
      width: 50%;

      > button {
        font-size: 16px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 0px;
        text-align: center;
      }
    }
  }
