.container {
  width: 400px;
  margin-right: 20px;

  .field {
    width: 400px;
    background-color: #FFF;

    label {
      color: #d4d4d4;
    }

    .adornmentButton {
      margin-left: 0;
    }

    .adornmentIcon {
      margin-right: 6px;
      margin-left: 6px;
    }

    >div {
      border-radius: 0;
      padding-right: 0;

      input {
        border-right: 1px solid #d4d4d4;
      }
    }
  }
}
