.dialogContainer {
    padding: 60px;
    width: 395px;
    height: 165px;
    font-family: 'Proxima Nova', 'Roboto', Sans-serif;
    box-sizing: content-box;

    .content {
        display: flex;
        flex-direction: column;
        height: 100%;

        .title {
            font-size: 24px;
            font-weight: bold;
            color: #666666;
            margin-bottom: 20px;
        }

        .message {
            font-size: 18px;
            font-weight: 600;
            color: #666666;
        }

        .actionItems {
            display: flex;
            justify-content: space-between;
            flex: auto;
            align-items: flex-end;

            .cancelButton {
                height: 38px;
                background-color: #ffffff;
                border: 1px solid #0091ea;
                color: #0091ea;
            }

            .cancelButton:hover {
                background-color: #0091ea0b;
            }

            .cancelButton.disabled {
                border: 1px solid #cccccc;
                color: #cccccc;
            }

            .proceedButton {
                height: 38px;
                color: #ffffff;
                background-color: #0091ea;
                border: none;

                span {
                    font-weight: 600;
                }
            }

            .proceedButton:hover {
                background-color: #0091eaf0;
            }

            .proceedButton.disabled {
                border-color: #eeeeee;
                color: #cccccc;
                background-color: #eeeeee;
            }
        }
    }
}

@media (max-width: 530px) {
    .actionItems {
        display: contents !important;

        .proceedButton,
        .cancelButton {
            margin: 10px;
        }
    }
}