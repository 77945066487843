.note {
  margin: 0 4px 30px 4px;
  width: 92%;
  align-self: center;

  .header {
    width: 100%;
    display: flex;
    margin-bottom: 10px;

    .headerIcon {
      width: 24px;
      height: 24px;
    }

    .headerText {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0 10px;

      .name {
        font-weight: bold;
        font-size: 12px;
        line-height: 12px;
      }

      .timestamp {
        font-size: 12px;
        line-height: 12px;
      }
    }
  }

  .messageContainer {
    width: 100%;
    display: flex;
    flex-direction: row;

    .message {
      position: relative;
      display: flex;
      align-items: center;
      flex: 1;
      font-size: 12px;
      line-height: 12px;
      padding: 0 20px;
      background: #e8e8e8;
      border-radius: 10px;
      overflow-x: auto;

      p {
        margin: 0;
      }

      .edited {
        color: #888888;
      }
    }

    .editContainer {
      position: relative;
      display: flex;
      align-items: center;
      flex: 1;
    }

    .input {
      flex: 1;
      font-size: 12px;
      line-height: 12px;
      padding: 20px;
      border-radius: 10px;
      border: 1px solid #cccccc;

      &:focus-visible {
        border: 1px solid #cccccc !important;
        outline: none;
      }
    }

    .actions {
      height: 52px;
      display: flex;
      flex-direction: column;
      flex: 0 0 35px;
      justify-content: space-between;
      align-items: center;

      .iconButton {
        width: 18px;
        height: 18px;

        svg {
          width: 18px;
          height: 18px;
        }
      }

      .iconButton:first-of-type {
        svg {
          color: #0091ea;
        }
      }

      img {
        cursor: pointer;
      }

      img:first-of-type {
        width: 17px;
        height: 17px;
      }

      img:nth-of-type(2) {
        width: 25px;
        height: 25px;
      }
    }
  }
}