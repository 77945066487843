.contentContainer {
  height: 100%;
  .heading {
    font-size: 18px;
    line-height: 22px;
    font-weight: 900;
    padding-left: 25px;
    padding-bottom: 16px;
    color: #000;
    margin-left: -20px;
    margin-right: -20px;
    border-bottom: 1px #F0F0F0 solid;
  }

  .heading2 {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    padding-left: 5px;
    padding-top: 15px;
    color: #000;
    margin-bottom: 30px;

    .typeLabel {
      width: 126px;
    }

    .typeEditor {
      width: 213px;
      height: 48px;
      margin: 0px;
    }

    .typeEditor>div>div {
      padding-top: 16px !important;
      padding-bottom: 16px !important;
      font-size: 16px;
      color: #333333;
    }

    .link {
      width: calc(100% - 329px);
      text-align: right;
      font-weight: 700;
      font-size: 14px;
      color: #0091ea;
      text-decoration: #0091ea underline;
      cursor: pointer;
    }
  }

  .tabBarContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    padding-top: 0.5em;
    background-color: white;
    height: 38px;
    margin-top: 10px;
    border-bottom: 1px solid #CCC;
    box-sizing: border-box;

    .navItem {
      display: flex;
      height: 100%;
      align-items: center;
      color: #000;
      padding: 0 16px;
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;
      border-bottom: 2.5px solid transparent;
      font-family: 'Proxima Nova', 'Roboto', Sans-serif;
      text-decoration: none;

      &.active {
        color: #0091ea;
        border-bottom: 2.5px solid #0091ea;
        border-bottom-width: calc(100% + 32px);
      }
    }
  }

  .scrollable {
    height: 405px;
    overflow-y: auto;
    scrollbar-gutter: stable both-edges;
  }

  .hiddenActions {
    height: calc(100% - 70px);
  }

  .tab {
    margin-top: 20px;
  }

  .hidden {
    display: none;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.03);

    button {
      text-transform: none;
      font-weight: bold;
      width: 48%;
      font-size: 16px;

      &:first-of-type {
        &:hover {
          background-color: transparent;
        }
      }
    }
  }

  .newFooter {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;

    button {
      text-transform: none;
      font-weight: bold;
      width: 48%;
    }
  }

  .moduleContainer {
    flex-direction: column;
    align-items: center;
    width: 100%;
    background: #FFFFFF;
    top: -15px;
  }

  .filesModuleContainer {

    >div>div>div>p {
      height: 30px;
      display: flex;
      align-items: center;
    }
  }
}

.typeRoot {
  ul {
    padding: 0px;
  }
}

.typeOption {
  li {
    font-size: 16px;
    color: #666;
    height: 48px;
  }
}
