.uploadWrapper {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: 1px dashed #000000;
  border-radius: 10px;
  height: 150px;
  width: 100%;

  >label {
    margin-top: 16px;
    font-size: 14px;
    font-weight: bold;   
  
    >span {
      color: #0091EA;
    }
  }

  .dim {
    opacity: 0.1;
  }
}