.container {
  padding-bottom: 20px;

  .vinEditMessage {
    color: #FF9000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-left: 30px;
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-left: 30px;
    margin-right: 30px;

    > div {
      width: 48%;

      > div {
        border-radius: 0;
      }

      label {
        font-size: 16px;
        top: 8px;
      }

      > div > input {
        font-size: 16px;
        padding-top: 23px;
        padding-bottom: 6px;
      }
    }
  }
}