.labelRoot {
  top: 2px !important;
}

.select {
  font-size: 16px;
  height: 52px;
  margin-top: 3px;
}

.item {
  font-weight: 400;
  font-size: 16px;
  font-style: normal;
}

.errorHelper {
  color: #f44336;
}