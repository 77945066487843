.cardTitle {
    padding-bottom: 1px !important;

    >label {
        position: static;
        width: 140px;
        height: 16px;
        left: 5px;
        top: 10px;
        font-family: 'Proxima Nova', 'Roboto', Sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-transform: capitalize;
        color: #000000;
        float: left;
        margin-top: 10px;
        margin-left: 5px;
    }
}

.actionIcon {
    color: #0091EA;
    float: right;
    cursor: pointer;
}

.cardFiles {
    width: 100%;
    height: auto;
    margin-top: 35px;
    border-radius: 10px !important;
    margin-bottom: 30px;
    border: solid;
    border-width: 0.1px;
    border-color: lightgray;
    box-shadow: unset !important;
    font-size: 16px;
    position: static;
    font-family: 'Proxima Nova', 'Roboto', Sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;

    .filesList {
        width: 95%;
    }

    .fileExtension {
        background-color: #000000;
        color: white;
        border-radius: 10px !important;
        padding: 3px 5px;
    }

    .fileText {
        text-decoration-line: underline;
        color: #0091EA;
        cursor: pointer;
        padding-left: 10px;
    }
}

.noFiles {
    position: static;
    width: fit-content;
    height: 12px;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Proxima Nova', 'Roboto', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 12px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1px;
    color: #CCCCCC;
    padding: 20px;
}