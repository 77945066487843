.closeBtn {
  position: relative;
  top: 5px;
  cursor: pointer;
  float: right;
}

.summaryTitle {
  position: relative;
  width: 131px;
  height: 22px;
  left: 10px;
  top: 10px;
  font-family: 'Proxima Nova', 'Roboto', Sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

.contentContainer {
  padding: 8px 0 !important;
  font-family: 'Proxima Nova', 'Roboto', Sans-serif;

  &.scrollable {
    height: 100%;
    overflow-y: auto;
    width: 100%;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px lightgray;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
  }

  &.hiddenScroll {
    height: 100%;
    overflow-y: hidden;
    width: 100%;
  }

  .label {
    font-size: 18px;
    font-weight: bold;
    color: #000;
    margin-left: 20px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 5px;
    height: 30px;
    margin-top: 15px;
    color: #000;

    .iconButton {
      margin-right: 20px
    }
  }

  .block {
    border-radius: 10px;
    border: 1px solid #E8E8E8;
    padding: 0 20px;
    margin: 10px 20px;

    .id {
      display: flex;
      margin-bottom: 15px;
      margin-top: 10px;
      height: 105px;

      .activeContainer {
        display: flex;
        flex-basis: 50%;

        >:first-child {
          width: 158px;
          height: 105px;
          border-radius: 10px;
          overflow-wrap: anywhere;
        }

        .active {
          display: flex;
          align-items: center;
          margin-left: 10px;

          .activeText {
            font-size: 14px;
          }
        }

        .switch {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 10px;
        }
      }

      >:nth-child(2) {
        flex-basis: 50%;
        align-items: center;
      }
    }

    .data {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      .item {
        display: flex;
        font-size: 14px;
        width: 50%;
        line-height: 30px;

        .innerLabel {
          font-weight: bold;
          margin-right: 15px;
          color: #000;
        }

        .innerValue {
          color: #666;
          overflow-wrap: anywhere;
        }
      }
    }
  }

  .companyOwned {
    font-weight: bold;
    margin-right: 15px;
    color: #000;
    line-height: 32px;
  }

  .filesList {
    margin-left: 20px;
    margin-right: 20px;
  }

  .comments {
    margin-top: 30px;
    margin-bottom: 30px;

    .link {
      color: #0091EA;
      margin-left: 30px;
      font-size: 16px;
      font-weight: 900;
      text-decoration: none;
      cursor: pointer;
    }
  }

  .dotActive {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 15px;
    background-color: #72bf44;
  }

  .dotInactive {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 15px;
    background-color: #ccc;
  }
}