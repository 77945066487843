.navItem {
    display: flex;
    height: 100%;
    align-items: center;
    color: #333333;
    padding: 0 16px 16px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    border-bottom: 1px solid #F0F0F0;
    font-family: 'Proxima Nova', 'Roboto', Sans-serif;
    text-decoration: none;

    &.active,
    &:hover {
        color: #0091ea;
        border-bottom: 2.5px solid #0091ea;
    }
}

.warning {
    color: red;
}