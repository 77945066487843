.container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 30px;
  margin-right: 30px;

  .docs {
    display: flex;
    flex-wrap: wrap;
    gap: 27px;
    margin-bottom: 30px;

    // Tile
    >div {
      border-radius: 10px;
      width: 214px;
      height: 182px;
      margin-right: 0;
      border: 1px solid #E8E8E8;

      // Thumbnail container
      >div:nth-of-type(1) {
        border-radius: 10px;
        width: 214px;
        height: 182px;

        img {
          width: 100%;
          height: auto;
        }

        // .react-pdf__Document
        >div {
          width: 100%;
          height: auto;

          // .react-pdf__Page
          >div {
            width: 100%;
            height: auto;

            canvas {
              width: 100% !important;
              height: auto !important;
            }

            // .react-pdf__Page__textContent
            >div {
              width: 100%;
              height: auto;
            }
          }
        }
      }

      // Filename
      p {
        color: #000;
        font-weight: normal;
        text-overflow: ellipsis;
        top: initial;
        bottom: -15px;
        background-color: #fff;
        width: calc(100% - 5px);
        border-radius: 0 0 10px 10px;
        border-bottom: 1px solid #E8E8E8;
        text-align: left;
        padding-left: 5px;
      }

      // Document overlay
      >div:nth-of-type(2) {
        border-radius: 10px;
        width: 214px;
        height: 182px;
        cursor: pointer;
      }

    }
  }
}

.scrollY {
  overflow-y: scroll;
}

.hideOverflow {
  overflow-y: hidden;
}