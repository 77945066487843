.root {
  flex-grow: 1;
}

.container {
  position: relative !important;
}

.suggestionsContainerOpen {
  position: absolute !important;
  z-index: 999;
  margin-top: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: table;
}

.suggestion {
  display: block !important;
}

.suggestionsList {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.divider {
  height: 2px;
}

.searchTextField {
  width: 100%;
}

.searchLoader {
  background-color: #b3daef !important;
  position: absolute !important;
  bottom: 0;
  left: 0;
  right: 0;
}

.searchTextField > div {
  height: 49px;
  font-size: 16px;
  color: #444444;
}

.searchTextField > label {
  font-size: 13px;
}

.searchTextField {
  margin-top: 8px !important;
}
