.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 230px;
    margin-top: 50px;

    .messagesContainer {
        flex: 1;
        display: flex;
        width: 100%;
        flex-direction: column;
        overflow-y: overlay;
        position: relative;
    }
}

.field {
    position: relative;
    height: 76px;
    width: 100% !important;
    margin-top: 20px;
    padding-top: 25px;

    .noteSendIcon {
        width: 24px;
        height: 21px;
        position: absolute;
        top: calc(50% - 10px);
        right: 15px;
        cursor: pointer;
    }
    >div>div>div{
        padding-top: 10px;
    }
}

.field>div>div>div:nth-of-type(1) {
    padding: 8px 50px 8px 0;
    border-radius: 0;
    height: 35px;

    textarea {
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 15px;
    }

    label {
        display: block;
        padding-left: 15px;
        top: 15px !important;
    }

    textarea:focus~label {
        display: none !important;
    }
}