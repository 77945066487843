.dialogContainer {
  >div:nth-child(3)>div:first-child {
    overflow-x: hidden;
    overflow-y: hidden;
  }
}

.vinModal {
  padding: 8px 16px 25px 16px;
}

.modalHeader {
  display: flex;
  border-bottom: 1px solid #f0f0f0;
  margin-left: -16px;
  margin-right: -16px;

  > .headerText {
    > h2 {
      font-family: 'Proxima Nova', 'Roboto', Sans-serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }

    display: flex;
    justify-content: flex-start;
    width: 90%;
    align-items: center;
    margin-left: 16px;
  }

  > .closeIcon {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 10%;
    color: #8c8c8c;
    margin-right: 16px;
    svg {
      cursor: pointer;
      }
  }

}

.modalBody {
  > p {
    font-family: 'Proxima Nova', 'Roboto', Sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    width: auto;
  }


  > .textBox {
    display: flex;
    width: auto;
    height: 52px;
  }
}

.modalFooter {
  margin-top: 22px;
  display: flex;

  > .skipButton {
    display: flex;
    justify-content: flex-start;
    width: 50%;
    > p {
      font-family: 'Proxima Nova', 'Roboto', Sans-serif;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      color: #0091ea;
      text-decoration: #0091ea underline;
      cursor: pointer;
    }
  }

  > .searchButton {
    display: flex;
    justify-content: flex-end;
    width: 50%;

    > button {
      font-family: 'Proxima Nova', 'Roboto', Sans-serif;
      font-size: 16px;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: center;
      width: 75%;
    }
  }
}
