.root {
  z-index: 99999 !important;
}

.dialogContainer {
  padding: 60px;
  width: 395px;
  height: 165px;
  font-family: 'Proxima Nova', 'Roboto', Sans-serif;
  box-sizing: content-box;

  .loadingContainer {
    position: absolute;
    z-index: 19;
    background-color: #888888;
    opacity: 0.3;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  .loadingSpinner {
    position: absolute;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
  }

  .content {
    display: flex;
    flex-direction: column;
    height: 100%;

    .title {
      font-size: 24px;
      font-weight: bold;
      color: #666666;
      margin-bottom: 20px;
    }

    .message {
      font-size: 18px;
      font-weight: 600;
      color: #666666;
    }

    .actionItems {
      display: flex;
      justify-content: space-between;
      flex: auto;
      align-items: flex-end;

      .cancelButton {
        width: 175px;
        height: 38px;
        background-color: #ffffff;
        border: 2px solid #0091ea;
        color: #0091ea;
        font-weight: 500;
      }

      .cancelButton:hover {
        background-color: #0091ea0b;
      }

      .cancelButton.disabled {
        border: 1px solid #cccccc;
        color: #cccccc;
      }

      .proceedButton {
        height: 38px;
        width: 95px;
        color: #ffffff;
        background-color: #0091ea;
        border: none;

        span {
          font-weight: 600;
        }
      }

      .proceedButton:hover {
        background-color: #0091eaf0;
      }

      .proceedButton.disabled {
        border-color: #eeeeee;
        color: #cccccc;
        background-color: #eeeeee;
      }
    }
  }
}

@media (max-width: 611px) {
  .dialogContainer {
    width: 100vw;
    margin: 0 !important;
  }
}