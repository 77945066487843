.part .highlightedPart {
  line-height: 25px;
}

.part {
  font-weight: 400;
  font-size: 16px;
  text-transform: capitalize;
  font-style: normal;
}

.highlightedPart {
  font-weight: 600;
  font-size: 16px;
}

.suggestion {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fieldFocused {
  background-color: rgba(0, 0, 0, 0.06);
  height: 52px;
  margin-top: 4px;

  label {
    font-size: 13px !important;
    top: -21px !important;
    padding-left: 15px;
  }

  input {
    font-size: 16px;
    padding-left: 15px;
    padding-bottom: 13px;
  }

  >div>div {
    margin-top: 4px !important;

    >p {
      padding-left: 15px;
    }

    >div {
      height: 32px;
    }
  }
}

.field {
  background-color: rgba(0, 0, 0, 0.06);
  height: 52px;
  margin-top: 4px;

  label {
    top: -8px !important;
    padding-left: 15px;
    // font-size: 16px;
  }

  input {
    font-size: 16px;
    padding-left: 15px;
    padding-bottom: 13px;
  }

  >div>div {
    margin-top: 4px !important;

    >p {
      padding-left: 15px;
    }

    >div {
      height: 32px;
    }
  }
}
