.tile {
  position: relative;
  width: 106px;
  height: 145px;
  border-radius: 6px;
  margin-right: 16px;
  justify-content: center;
  display: flex;

  p {
    color: white;
    position: absolute;
    font-weight: 600;
    width: 106px;
    top: 100px;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
  }

  .uploader,
  .thumb {
    top: 0;
    left: 0;
    width: auto;
    height: 106px;
    margin-right: 0;
  }
  .thumbnailContainer {
    border: 3px #cccccc;
    background-color: #cccccc;
    height: 106px;
    width: 106px;
    display: flex;
    overflow: hidden;
    justify-content: center;
    border-radius: 6px;
  }
  .uploader {
    background-color: #cccccc;
    align-items: center;
    display: flex;
    justify-content: space-around;
    width: 100%;
    border-radius: inherit;
  }

  .fileName {
    font-size: 14px;

    .extensionIcon {
      color: #fff;
      background-color: #0091ea;
      font-weight: 700;
      padding: 5px 10px;
      margin-right: 5px;
      border-radius: 10px;
    }
  }
  .noExtension {
    word-break: break-all;
    white-space: normal;
    font-size: 12px;
    width: 81px;
    color: red;
  }

  .loadingText {
    left: 0;
  }
}

.tile.viewOnly {
  border-radius: 4px;
  height: 80px;
  width: 80px;
  margin: 0 5px 20px 5px;

  .fileName {
    overflow: hidden;
    word-wrap: normal;
    white-space: nowrap;
    color: #666666;
    font-size: 12px;
  }

  .uploader,
  .thumb {
    height: 80px;
    width: auto;
  }

  .thumbnailContainer {
    height: 80px;
    display: flex;
    overflow: hidden;
    justify-content: center;
    border-radius: 6px;
  }

  p {
    top: 71px;
    width: 80px;
  }
}

.overlay {
  border-radius: 6px;
  background-color: #04040f;
  width: 106px;
  height: 106px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  text-align: right;
  .deleteIcon {
    cursor: pointer;
    color: #ffffff;
    width: 25px;
    height: 25px !important;
    position: absolute;
    top: 4px;
    right: 4px;
    font-size: 21px;
  }
  button {
    cursor: pointer;
    width: 25px;
    opacity: 0;
    height: 25px;
    top: 4px;
    right: 4px;
    position: absolute;
  }
}

.viewOnly .overlay {
  height: 80px;
  width: 80px;
  cursor: pointer;
  border-radius: 4px;
}

.overlay:hover {
  opacity: 0.7;
}
