.chips {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 15px;
  flex: 0 0 auto;
  font-size: 14px;
  align-content: center;
  margin-top: 10px;
  margin-bottom: 10px;

  // chipWrapper
  >div {
    border-radius: 20px;
    height: 32px;
    display: flex;
    align-items: center;
    padding: 1px 10px 1px 10px;

    // Chip
    >div {
      color: #000;
      padding: 0;
      margin: 0;
      background-color: transparent;

      >div {
        margin-right: 0;
      }
    }
  }

  .chipWrapper {
    background-color: #EEEEEE;
  }

  .dotAll {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
    margin-right: 5px;
    background-color: #62C3FF;
  }

  .dotActive {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
    margin-right: 5px;
    background-color: #72bf44;
  }

  .dotInactive {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
    margin-right: 5px;
    background-color: #EF3824;
  }

  .dotTruck {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
    margin-right: 5px;
    background-color: #FF9000;
  }

  .dotOwner {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
    margin-right: 5px;
    background-color: #0091EA;
  }

  .dotCompany {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
    margin-right: 5px;
    background-color: #F7B500;
  }
}