.dialog {
    position: relative;

    >div>div {
      border-radius: 10px;
      width: 830px;
      height: 762px;
      padding: 20px 20px 0 20px;
      box-sizing: content-box;

      >div:nth-of-type(1) {
        right: 4px;
        top: 10px;
      }

      >div:nth-of-type(2) {
        margin-bottom: 27px;
      }
    }
}

.container {
    position: relative;
    margin-left: 20px;
    margin-right: 20px;

    .modalHeading {
        font-size: 18px;
        font-weight: 900;
        line-height: 22px;
        margin-bottom: 20px;
    }

    .imageUploadContainer {
        width: 100%;
        height: 560px;

        .modalImageContainer {
            height: auto;
            width: auto;
            padding: 20px;
            border-radius: 10px;
            border: 1px solid #E8E8E8;
            overflow: hidden;

                .modalImage {
                    max-width: 750px;
                    max-height: 520px;
                    height: auto;
                    width: auto;
                }
        }
    }
}

.actions {
    display: flex;
    justify-content: space-between;
    margin-left: 20px;
    margin-right: 20px;

    button {
        width: 48%;
        text-transform: none;
        font-weight: 700;
        font-size: 16px;

        &:first-of-type {
            &:hover {
                background-color: transparent;
            }
        }
    }
}